<template>
  <div class="cancelAccount flexcolumnstart">
    <img
      src="https://sho-static.shulan.com/minipro/jinshi.png"
      class="accountTipImg"
    />
    <h3>申请注销当前账号</h3>
    <div class="flexrowstart aligncentet">
      <span class="tip"></span>
      <span class="content">您与患者的交流记录将丢失</span>
    </div>
    <div class="flexrowstart aligncentet marginTop10 marginBottom10">
      <span class="tip"></span>
      <span class="content">您已完成的订单、处方信息将丢失</span>
    </div>
    <div class="flexrowstart aligncentet ">
      <span class="tip"></span>
      <span class="content">账户余额、视频课程等资产权益将丢失</span>
    </div>
    <div class="activeBtn marginTop70" @click="applayAccount">申请注销</div>
    <p class="caontact">
      联系客服：
      <span @click="bindPhone">{{phone}}</span>
    </p>
    <div class="flexrowcenter aligncentet notice width100">
      <span
        class="uncheckedRadio marginRight12"
        v-if="!isagree"
        @click="agreeNotice"
      ></span>
      <img
        @click="agreeNotice"
        v-else
        class="checkedRadio marginRight12"
        src="https://sho-static.shulan.com/minipro/doctoragree.png"
      />
      <p>
        我已阅读并同意
        <span class="accountNotice" @click="seeDetail">
          《树兰医生账户注销须知》
        </span>
      </p>
    </div>
    <div class="g-safe-area-bottom"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isagree: false,
      phone:'400-103-9999'
    }
  },
  methods: {
    bindPhone(){
      window.location.href='tel://'+this.phone
    },
    agreeNotice() {
      this.isagree = !this.isagree
    },
    applayAccount() {
      if (!this.isagree) {
        this.$toast.fail('请先阅读并同意《树兰医生账号注销须知》')
      } else {
        this.$router.replace({
        path:'/cancelAccount/cancelReason',
      })
      }
    },
    seeDetail() {
      this.$router.push({
        path:'/cancelAccount/cancelNotice'
      })
    },
   
  },
  created() {},
  beforeCreate() {},
}
</script>
<style lang="scss">
.van-toast {
  width: 300px !important;
}
.cancelAccount {
  background: #f7f9fc;
  padding: 94px 0 110px;
  .accountTipImg {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  h3 {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    margin: 18px 0 110px;
    text-align: center;
  }
  .tip {
    width: 12px;
    height: 12px;
    background: #040814;
    border-radius: 6px;
    margin-left: 94px;
    margin-right: 27px;
  }
  .content {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .notice {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    position: fixed;
    bottom: 110px;
  }
  .accountNotice {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5771f9;
  }
  .caontact {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    margin-top: 40px;
    text-align: center;
     span {
      color: #5771F9;
    }
  }
}
</style>
